import React, { useState, useRef, useImperativeHandle, useLayoutEffect } from 'react';
import { useHistory } from 'react-router-dom';
import clsx from 'clsx';
import { Button, Tabs, Tab, TabPanel, Box } from '@common/components/';
import { refIsRequiredError } from '@util/utils';

const Stepper = React.forwardRef((props, ref) => {
    const {
        stepSourceContent: tabsStepSourceData,
        onTemporaryStorageEvent,
        onSubmitEvent,
        onChange: onChangeProps,
        isTemporary = true,
        isSubmit = true,
        isCancel = true,
        onCancelClick: onCancelClickProps = null,
        tabsProps = {},
        tabsFooterProps = {},
        TabPanelProps = {},
        ...others
    } = props;
    const [tabsIndex, changeTabIndex] = useState(0);
    const history = useHistory();
    const contentRef = useRef(null);
    const isInitRef = useRef(true);
    const tabRef = useRef();

    const handleChangeTab = (e, index) => {
        if (index < 0 || index >= tabsStepSourceData.length) return false;
        onChangeProps && onChangeProps(tabsIndex);
        changeTabIndex(index);
    };

    const handleCancel = () => {
        if (onCancelClickProps && onCancelClickProps instanceof Function) {
            onCancelClickProps();
        } else {
            history.goBack();
        }
    };

    const handleTemporaryStorage = (e) => {
        onChangeProps && onChangeProps(tabsIndex);
        onTemporaryStorageEvent && onTemporaryStorageEvent(e);
    };

    const handleSubmit = (e) => {
        onChangeProps && onChangeProps(tabsIndex);
        onSubmitEvent && onSubmitEvent(e);
    };

    useImperativeHandle(
        ref,
        () => ({
            getResult: () => {
                if (contentRef.current && contentRef.current.hasOwnProperty('getResult')) {
                    return contentRef.current.getResult();
                }
            },
            isError: () => refIsRequiredError(contentRef),
            setParams: () => {
                if (contentRef.current && contentRef.current.setParams) return contentRef.current.setParams;
            },
        }),
        // eslint-disable-next-line
        []
    );

    useLayoutEffect(
        () => {
            if (!tabRef.current) return null;
            if (!window) return null;
            let scrollToTop = 0;

            if (!isInitRef.current) {
                window.scrollTo({
                    top: scrollToTop,
                    behavior: 'smooth',
                });
            } else {
                isInitRef.current = false;
            }
        },
        // eslint-disable-next-line
        [tabsIndex, tabRef]
    );

    const tabPanelUseMemo = React.useMemo(
        () => {
            return tabsStepSourceData.map(({ id, content }, index) => {
                return (
                    <TabPanel className={clsx('stepper-tabs-panel', TabPanelProps.className)} key={id} value={tabsIndex} index={index}>
                        {React.cloneElement(content, { ref: contentRef, ...others })}
                    </TabPanel>
                );
            });
        },
        // eslint-disable-next-line
        [tabsIndex]
    );

    return (
        <React.Fragment>
            <Tabs ref={tabRef} className={clsx('stepper-tabs', tabsProps.className)} value={tabsIndex} onChange={handleChangeTab}>
                {tabsStepSourceData.map(({ id, label, isShowState }, index) => {
                    return <Tab key={id} index={index} label={label} className={clsx({ state: isShowState })} />;
                })}
            </Tabs>
            {tabPanelUseMemo}
            <Box className={clsx('stepper-tabs-footer', tabsFooterProps.className)}>
                <Box className="btn-group">
                    {tabsIndex !== 0 && (
                        <Button variant="outlined" color="secondary" className="btn-minWidth prev" onClick={(e) => handleChangeTab(e, tabsIndex - 1)}>
                            上一步
                        </Button>
                    )}
                    {
                        <Box className="btn-group">
                            {isCancel && (
                                <Button color="secondary" className="btn-minWidth" onClick={handleCancel}>
                                    取消
                                </Button>
                            )}
                            {isTemporary && (
                                <Button name="temporary" variant="contained" color="secondary" className="btn-minWidth" onClick={handleTemporaryStorage}>
                                    暫存檔案
                                </Button>
                            )}
                            {isSubmit && tabsIndex === tabsStepSourceData.length - 1 && (
                                <Button name="create" variant="contained" color="secondary" className="btn-minWidth" onClick={handleSubmit}>
                                    確認送出
                                </Button>
                            )}
                        </Box>
                    }

                    {tabsIndex !== tabsStepSourceData.length - 1 && (
                        <Button variant="outlined" color="secondary" className="btn-minWidth next" onClick={(e) => handleChangeTab(e, tabsIndex + 1)}>
                            下一步
                        </Button>
                    )}
                </Box>
            </Box>
        </React.Fragment>
    );
});

export default Stepper;
